
.title-main {
    color: #2D3748;
    font-size: xx-large;
    font-weight: 500;
}

.title-second {
    color: #2D3748;
    font-size: x-large;
    font-weight: 500;
}

.subtitle-dark {
    color: #2D3748;
    font-size: medium;
    font-weight: 600;

}

.description-medium {
    color: #4A5568;
    font-size: medium;
    font-weight: 400;
    margin-bottom: 5px;
}

.description-small {
    color: #4A5568;
    font-size: small;
    font-weight: 300;
}

.value-main {
    color: #A57C00;
    font-size: xx-large;
    font-weight: 700;
    margin-top: 10px;
}

.value-second {
    color: #4A5568;
    font-size: large;
    font-weight: 600;
    margin-top: 10px;
}

.card-dark {
    background-color: #f0f0f0;
    color: #CEBC80;
    border-radius: 10px;
    padding: 40px;
}

.container-white {
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: 1px solid;
}

.text-gain {
    color: green;
    font-weight: 600;

}

.button-container {
    display: flex;
    text-align: end;
    margin-top: 25px;
}

.icon-container {
    display: flex;
    justify-content: center;
    color: red
}

.text-warning {
    color: red;
    font-weight: 500;
}

.value-with-label {
    margin-bottom: 20px;
}