/* AWS AMPLIFY - LOGIN  */
[data-amplify-authenticator] {
    height: 100vh;
    background-color:#2D3748;
    /* margin-top: 100px; */
  }
  
  [data-amplify-authenticator] [data-amplify-container] {
    width: 40%;
  }
  
  [data-amplify-authenticator] [data-amplify-router] {
    border: 2px solid;
    border-color: #CEBC80;
    border-radius: 20px;
    box-shadow: 1px 1px 1px #ffffff;
  }
  
  .amplify-button--primary {
  
    background-color: #CEBC80;
    color: #2D3748;
  }
  
  .amplify-button--primary:hover {
  
    background-color: #2D3748;
    color: #CEBC80;
  }
  
  .amplify-tabs-item[data-state="active"] {
    color: #2D3748;
    border-color: #2D3748;
  }
  
  .amplify-tabs-item:hover {
    color: #CEBC80;
    border-color: #CEBC80;
  }
  
  #__next > div:nth-child(1) {
    background-color: #f1f1f1;  
  }
  
  .amplify-button--link{
    color: #2D3748;
  }
  
  .amplify-button--link:hover{
    background-color: #fff9e5;
  }

  
.login-container {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.login-img {
  background-image: url("./logo512.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.login-text {
  color: #CEBC80;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  line-height: 30px;
}

.footer-text {
  text-align: center;
  margin-top: 20px;
  color: #ffffff;

}