


.box-info-title {
    font-size: 0.8rem;
    font-weight: 300;
    color: #b08838;
}


.box-info-title-xl {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.3em;
    color: #b08838;
}


.box-info-value {
    font-size: 1rem;
    font-weight: 500;
    color: #2D3748;
}

.box-info-value-xl {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.6em;
    color: #2D3748;
}